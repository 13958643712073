<!-- <div class="dash-header">
    <h2>Dashboard</h2>
    <p>Welcome to your account dashboard.</p>
</div> -->
<div class="main-container mt-14">
    <div>
        <div class="dash-icons">
            <div class="dash-icon" (click)="loadCompent('playlists')">
                <ng-icon name="saxPlayCircleOutline" size="32"></ng-icon>
                <span>Playlists</span>
            </div>
            <div class="dash-icon" (click)="loadCompent('data')">
                <ng-icon name="saxFolder2Outline" size="32"></ng-icon>
                <span>Drive</span>
            </div>
            <div class="dash-icon" (click)="loadCompent('designs')">
                <ng-icon name="saxPathSquareOutline" size="32"></ng-icon>
                <span>Designs</span>
            </div>
            <div class="dash-icon" (click)="loadCompent('displays')">
                <ng-icon name="saxMonitorMobbileOutline" size="32"></ng-icon>
                <span>Displays</span>
            </div>             
            <div class="dash-icon" (click)="loadCompent('settings')">
                <ng-icon name="saxSetting2Outline" size="32"></ng-icon>
                <span>Settings</span>
            </div>

            <!-- <div *ngIf="authService.account.firmware_admin" class="dash-icon" (click)="loadCompent('firmware')">
                <ng-icon name="saxCpuOutline" size="32"></ng-icon>
                <span>Firmware</span>
            </div> -->
        </div>
    </div>
</div>