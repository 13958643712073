import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { Data } from 'src/app/api/models/account/data/data';
import { CopyDataComponent } from 'src/app/components/copy-data/copy-data.component';
import { DataFolderComponent } from 'src/app/components/data-folder/data-folder.component';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { MoveDataComponent } from 'src/app/components/move-data/move-data.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent {
  @ViewChild(DataFolderComponent) dataTableComponent!: DataFolderComponent;

  dataClass: DataClass = DataClass.Data;


  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  currentLocation() {
    return this.authService.getAccountBasePath() + '/data';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Create new item',
          dataClass: DataClass.Data,
          data: {
            name: 'New item ' + Math.floor(Math.random() * 1000),
            selectedClassType: 'Folder',
            parentId: event.item.parentId
          },
          width: '60em',
          height: '35em'
        }
      }).afterClosed().subscribe((result: Data) => {
        if (result) {
          if(result.selected_class.name == 'Folder'){
            this.dataTableComponent.folderTreeComponent.refreshDirs();
          }
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete item',
          message: 'Are you sure you want to delete this Item?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.dataTableComponent.setLoading.emit();
          this.apiService.deleteData(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete items',
          message: 'Are you sure you want to delete all selected items?'
        }
      }).afterClosed().subscribe(async (result: any) => {
        if (result) {
          this.dataTableComponent.setLoading.emit();
          for (let item of event.selected.selected) {
            await this.apiService.deleteData(this.authService.selectedAccountId, item.id).toPromise();
          }
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Edit) {
      this.dialog.open(EditComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Edit item',
          dataClass: DataClass.Data,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Move) {
      if (event.selected.selected.length == 0) {
        return;
      }
      this.dialog.open(MoveDataComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Move item',
          dataClass: DataClass.Data,
          data: JSON.parse(JSON.stringify(event.selected.selected)),
          width: '30em',
          height: '40em'
        }
      }).afterClosed().subscribe((result: Array<{ name: string, id: number }>) => {
        if (result) {
          this.dataTableComponent.setParent.emit(result);
        }
      });
    }

    if (event.action == TableChangeDataActions.Copy) {
      if (event.selected.selected.length == 0) {
        return;
      }
      this.dialog.open(CopyDataComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Copy content',
          data: JSON.parse(JSON.stringify(event.selected.selected)),
          width: 600,
          height: 700
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}
