import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-displays',
  templateUrl: './displays.component.html',
  styleUrls: ['./displays.component.scss']
})
export class DisplaysComponent {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent;

  dataClass: DataClass = DataClass.Displays;


  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  currentLocation(){
    return this.authService.getAccountBasePath() + '/displays';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'New Display',
          dataClass: DataClass.Displays,
          data: {
            name: 'New Display '+Math.floor(Math.random() * 1000),
          },
          width: '30em',
          height: '10em'
        }
      }).afterClosed().subscribe((result: any) => { 
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Display',
          message: 'Are you sure you want to delete this display?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.apiService.deleteDisplay(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Display',
          message: 'Are you sure you want to delete all selected display?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          event.selected.selected.forEach((item: any) => {
            this.apiService.deleteDisplay(this.authService.selectedAccountId, item.id).subscribe((data: any) => {
              this.updateTable();
            });
          });
        }
      });
    }

    if(event.action == TableChangeDataActions.Edit){
      this.dialog.open(EditComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Edit Display',
          dataClass: DataClass.Displays,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 400,
          height: 200
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }

}
