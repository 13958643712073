import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.scss']
})
export class FirmwareComponent {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent;

  dataClass: DataClass = DataClass.Firmware;


  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  currentLocation() {
    return this.authService.getAccountBasePath() + '/firmware';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'New Firmware',
          dataClass: DataClass.Firmware,
          data: {
            file: null,
            postScript: '',
            script: '',
            hardwareTypes: [],
          },
          width: 800,
          height: 400
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Firmware',
          message: 'Are you sure you want to delete this firmware?'
        }
      }).afterClosed().subscribe((result: any) => {
        this.apiService.removeFirmware(this.authService.account.id, event.item.id).subscribe((data: any) => {
          this.updateTable();
        });
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Firmware',
          message: 'Are you sure you want to delete all selected firmware?'
        }
      }).afterClosed().subscribe((result: any) => {

      });
    }

    if (event.action == TableChangeDataActions.Edit) {
      this.dialog.open(EditComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Edit Firmware',
          dataClass: DataClass.Firmware,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 1024,
          height: 600
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.apiService.updateFirmwareScripts(this.authService.account.id, result.id, result.post_script, result.script).subscribe((data: any) => {
            this.apiService.updateFirmwareStatus(this.authService.account.id, result.id, result.status).subscribe((data: any) => {
              this.updateTable();
            }, (error: any) => {
            });
          }, (error: any) => {
          });
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}
