<app-breadcrumbs [crumbs]="[{name:'Settings', link:currentLocation()}]"></app-breadcrumbs>
<div class="overlay">
    <h1 class="typeTitle">Settings</h1>
    <div class="settings-main">
        <div class="v-menu-container">
            <div class="v-menu-button" (click)="currentOption = 'info'" [class.v-menu-button-selected]="currentOption == 'info'">Account info</div>
            <div class="v-menu-button" (click)="currentOption = 'users'" [class.v-menu-button-selected]="currentOption == 'users'">Users</div>
        </div>
        
        <div class="settings-edit w-full">
            <div class="settings" *ngIf="currentOption == 'info'">
                <div class="flex flex-col gap-3">
                    <div>Account name</div>
                    <div><input pInputText style="width: 25em;" class="input" type="text" placeholder="account name" [(ngModel)]="authService.account.info.name" /></div>
                    <p-button class="float-right" label="Save" (click)="saveAccountInfo()" />
                </div>
            </div>
            <div class="settings w-full" *ngIf="currentOption == 'users'">
                <table class="table text-center w-full" *ngIf="authService.account.accountUsers.length > 0">
                    <thead>
                        <tr>
                            <th><div class="pl-3">User</div></th>
                            <th>Permissions</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let accountUser of authService.account.accountUsers">
                            <td>
                                {{accountUser.user.alias == '' ? 'Pending setup' : accountUser.user.alias}} <br/>
                                <p-chip  label="{{accountUser.user.authProviders[0].providerUUID}}" />
                            </td>
                            <td>
                                <p-multiselect (onChange)="onRolesChange(accountUser.user)" [options]="roles" showToggleAll="false" showHeader="false" [(ngModel)]="accountUser.user.roles" optionLabel="name" optionValue="id"  styleClass="w-full md:w-80" />
                            </td>
                            <td>
                                <p-button label="Delete" [outlined]="true" (click)="deleteUser(accountUser.id)" />
                            </td>
                        </tr>
                        <tr *ngFor="let invite of invites">
                            <td>
                                Invation sent <br/>
                                <p-chip label="{{invite.authProvider.providerUUID}}" />
                            </td>
                            <td>

                            </td>
                            <td>
                                <p-button label="Delete" [outlined]="true" (click)="deleteInvite(invite.id)" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex gap-2 mt-4">
                    <input pInputText style="width: 25em;" class="input" type="email" placeholder="email" [(ngModel)]="email" />
                    <p-button label="Add user" (click)="addUser()" />
                </div>
            </div>
        </div>
    </div>
</div>