import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { DataComponent } from '../components/data/data.component';
import { DatarowComponent } from '../components/datarow/datarow.component';
import { DesignsComponent } from '../components/designs/designs.component';
import { DisplaysComponent } from '../components/displays/displays.component';
import { PlaylistsComponent } from '../components/playlists/playlists.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { AccountComponent } from './account.component';
import { FirmwareComponent } from '../components/firmware/firmware.component';
import { PlaylistAccountComponent } from '../components/playlist/playlist.component';

const routes: Routes = [
  {
    path: 'editor',
    loadChildren: () => import('../editor/editor.module').then(m => m.EditorModule),
  },
  {
    path: '',
    component: AccountComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'displays', component: DisplaysComponent },
      { path: 'designs', component: DesignsComponent },
      { path: 'data', component: DataComponent },
      { path: 'data/:rowId', component: DatarowComponent },
      { path: 'playlists', component: PlaylistsComponent },
      { path: 'playlist/:playlistId', component: PlaylistAccountComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'firmware', component: FirmwareComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
