import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { NewItem } from 'src/app/models/new-item';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-designs',
  templateUrl: './designs.component.html',
  styleUrls: ['./designs.component.scss']
})
export class DesignsComponent {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent;

  dataClass: DataClass = DataClass.Designs;


  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  currentLocation(){
    return this.authService.getAccountBasePath() + '/designs';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'New Design',
          dataClass: DataClass.Designs,
          data: {
            name: 'New Design '+Math.floor(Math.random() * 1000),
            orientation: 'landscape',
            device: 'tv'
          },
          width: '30em',
          height: '20em'
        }
      }).afterClosed().subscribe((result: any) => { 
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Design',
          message: 'Are you sure you want to delete this design?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.apiService.deleteDesign(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Designs',
          message: 'Are you sure you want to delete all selected designs?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          event.selected.selected.forEach((item: any) => {
            this.apiService.deleteDesign(this.authService.selectedAccountId, item.id).subscribe((data: any) => {
              this.updateTable();
            });
          });
        }
      });
    }

    if(event.action == TableChangeDataActions.Edit){
      this.dialog.open(EditComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Edit Design',
          dataClass: DataClass.Designs,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 400,
          height: 200
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}
