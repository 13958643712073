import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-datarow',
  templateUrl: './datarow.component.html',
  styleUrls: ['./datarow.component.scss']
})
export class DatarowComponent {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent;

  dataClass: DataClass = DataClass.DataRow;

  id: number = 0;

  constructor(private apiService: ApiService, 
    private authService: AuthService, 
    private dialog: MatDialog, 
    private route: ActivatedRoute) {
      this.id = this.route.snapshot.params['rowId'];
  }

  currentLocation() {
    return this.authService.getAccountBasePath() + '/data';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'New Data',
          dataClass: DataClass.DataRow,
          data: {
            name: 'New Data ' + Math.floor(Math.random() * 1000),
            selectedClassType: 'Folder'
          },
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete this Data?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.apiService.deleteData(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        panelClass: 'custom-dialog-container',
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete all selected Data?'
        }
      }).afterClosed().subscribe(async (result: any) => {
        if (result) {
          for (let item of event.selected.selected) {
            await this.apiService.deleteData(this.authService.selectedAccountId, item.id).toPromise();
          }
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Edit) {
      this.dialog.open(EditComponent, {
        panelClass: 'custom-dialog-container',
        enterAnimationDuration: 0,
        data: {
          title: 'Edit Data',
          dataClass: DataClass.DataRow,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}
